<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="monthCard-edit">
            <el-form :model="form" ref="form" :rules="rules" label-width="100px" label-position="left" >
                <div class="a-fs-16 a-fw-700 a-flex-rfsc" style="margin-bottom: 16px">
                    <span>基本信息</span>
                </div>
                <el-form-item label="名称" prop="activityTitle">
                    <el-input class="inputBox" maxlength="32" clearable v-model="form.activityTitle" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="活动缩略图" prop="thumbnail">
                    <le-upload-file-img ref="pic" v-model="form.thumbnail" :limit='1'></le-upload-file-img>
                </el-form-item>
                <el-form-item label="类型" prop="activityRewardsType">
                    <el-radio-group v-model="form.activityRewardsType" :disabled="id?true:false" @change="rewardsTypeChange">
                        <el-radio 
                            v-for="(item,index) in activityRewardsTypeDic"
                            :key="index"
                            :label="item.value" >{{ item.text }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="内容" prop="activityRewardsValue">
                    <div slot="label">
                        <span v-if="form.activityRewardsType==0">天数</span>
                        <span v-if="form.activityRewardsType==1">立减</span>
                        <span v-if="form.activityRewardsType==2">折扣</span>
                        <span v-if="form.activityRewardsType==3">退款金额比例</span>
                        <span v-if="form.activityRewardsType==4">优惠券选择</span>
                        <span v-if="form.activityRewardsType==5">一口价</span>
                    </div>
                    <el-input 
                        v-if="form.activityRewardsType==0"
                        :disabled="id?true:false"
                        class="inputBox" 
                        clearable 
                        v-model="form.activityRewardsValue" 
                        placeholder="请输入" 
                        @input="form.activityRewardsValue=form.activityRewardsValue.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                    <jd-input-price 
                        v-if="form.activityRewardsType==1"
                        :disabled="id?true:false"
                        v-model="form.activityRewardsValue" 
                        style="width:240px"></jd-input-price>
                    <el-input 
                        v-if="form.activityRewardsType==2"
                        class="inputBox" 
                        clearable 
                        :disabled="id?true:false"
                        v-model="form.activityRewardsValue" 
                        placeholder="请输入" 
                        @input="form.activityRewardsValue=util.checkMoney1(form.activityRewardsValue)"></el-input>
                    <el-input
                        v-if="form.activityRewardsType==3"
                        placeholder="请输入"
                        class="inputBox" 
                        :disabled="id?true:false"
                        maxlength="3"
                        v-model="form.activityRewardsValue"
                        @input="form.activityRewardsValue=form.activityRewardsValue.replace(/^(0+)|[^\d]+/g,'')">
                        <i slot="suffix" class="el-input__icon">%</i></el-input>
                    <div v-if="form.activityRewardsType==4">
                        <div class="a-flex-rfsc a-mb-10" v-for="(item, index) in form.rewardsGoodsValidList" :key="index">
                            <le-select-remote-search 
                                :readonly="id?true:false"
                                large
                                isObj
                                class="coupon-item"
                                style="padding: 0 !important;"
                                v-model="item.goodsInfo" 
                                :options="optionsCoupon" 
                                placeholder="请选择（可输入搜索）" />
                            <span>，一份可领取</span>
                            <el-input 
                                :disabled="id?true:false"
                                style="width: 160px;margin: 0 10px"
                                clearable 
                                v-model="item.num" 
                                placeholder="请输入" 
                                @input="item.num=item.num.replace(/^(0+)|[^\d]+/g,'')">
                                <template slot="append">张</template>
                            </el-input>
                            <el-tooltip class="devList-btn" effect="dark" content="删除" placement="top" v-if="form.rewardsGoodsValidList.length > 1 && !id">
                                <img src="../../assets/icon/option-del-circle.png" @click="delCoupon(index)" class="a-wh-16 a-mlr-12" />
                            </el-tooltip>
                            <el-tooltip class="devList-btn" effect="dark" content="添加" placement="top" v-if="!id">
                                <img src="../../assets/icon/option-add-circle.png" @click="addCoupon" class="a-wh-16 a-mlr-12" />
                            </el-tooltip>
                        </div>
                    </div>
                    <jd-input-price 
                        v-if="form.activityRewardsType==5"
                        :disabled="id?true:false"
                        v-model="form.activityRewardsValue" 
                        style="width:240px"></jd-input-price>
                </el-form-item>
                <el-form-item label="活动规则" prop="activityType">
                    <el-radio-group v-model="form.activityType" :disabled="id?true:false" @change='activityTypeChange'>
                        <el-radio 
                            v-for="(item,index) in activityTypeDic"
                            :disabled='item.isDisabled'
                            :key="index"
                            :label="item.value" >{{ item.text }}</el-radio>
                    </el-radio-group>
                    <el-input v-if="form.activityType == 2" class="inputBox block" maxlength="32" clearable v-model="form.cdk" placeholder="请输入兑换码"></el-input>
                    <div class="a-flex-rfsc" v-if="form.activityType == 8">
                        <span>用户拥有充电优惠券</span>
                        <el-select v-model="form.activityTypeLimit" placeholder="请选择" :disabled="id?true:false" style="margin:0 10px">
                            <el-option
                                :disabled='item.value == 0 || item.value == 1 || item.value == 4'
                                v-for="item in compareTypeDic"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                        </el-select>
                        <el-input 
                            :disabled="id?true:false" 
                            style="width:240px" 
                            maxlength="4" 
                            v-model="form.activityTypeLimitValue" 
                            @input="form.activityTypeLimitValue=form.activityTypeLimitValue.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                        <!-- <jd-input-intnum v-model="form.activityTypeLimitValue" :disabled="id?true:false" placeholder="请输入" maxlength='4'></jd-input-intnum> -->
                    </div>
                </el-form-item>
                <el-form-item label="" prop="monthCardInfoValidList" v-if="form.activityType == 0">
                    <el-button size="mini" icon="el-icon-plus" type="primary" @click="choosePackage">选择商品</el-button>
                    <el-button size="mini" type="primary" plain @click="isHiddenGoods = !isHiddenGoods">{{!isHiddenGoods?'收起':'展开'}}</el-button>
                    <el-table
                        ref="multipleTable"
                        :border='true'
                        v-if="!isHiddenGoods"
                        :data="form.monthCardInfoValidList"
                        style="width: 1000px;margin-top: 10px">
                        <el-table-column
                            width='80'
                            label="商品ID">
                            <template slot-scope="{ row }">
                                <span>{{ row.goodsId }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="商品名称">
                            <template slot-scope="{ row }">
                                <span>{{ row.goodsName }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column 
                            v-if="form.activityRewardsType!=5"
                            label="类型" >
                            <template slot-scope="{ row }">
                                <span>{{ row.isTempCard | initDic(isTempCardDic) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="form.activityRewardsType!=5"
                            label="项目">
                            <template slot-scope="{ row }">
                                <span>{{ row.incomeDistriName || '-' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="form.activityRewardsType!=5"
                            label="站点">
                            <template slot-scope="{ row }">
                                <div class="a-flex-rfsc a-flex-wrap">
                                    <span class="stationTag" v-for="(item,index) in row.stationList" :key="index">{{ item.name?item.name:'-' }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="生效日期">
                            <template slot-scope="{ row }">
                                <span>{{ row.startTime }} 至 {{ row.endTime }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="操作"
                            width='60'>
                            <template slot-scope="scope">
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <img src="../../assets/icon/option-del-circle.png" class="a-wh-16" @click="delMonthCardInfoValidList(scope.$index)"/>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item 
                    label="影响范围" 
                    prop="joinScope" 
                    v-if="(form.activityRewardsType==4 && (form.activityType==1 || form.activityType == 4  || form.activityType == 5 || form.activityType == 8)) ||
                        (form.activityRewardsType==2 && form.activityType==6) ||
                        (form.activityRewardsType==5 && form.activityType==6) || 
                        (form.activityRewardsType==5 && form.activityType==0)
                    ">
                    <div class="a-flex-rfsc">
                        <el-select style="width: 190px" v-model="form.joinScope" placeholder="请选择">
                            <el-option label="全部" :value="1"></el-option>
                            <el-option label="指定范围" :value="2"></el-option>
                        </el-select>
                        <el-select style="width: 225px;margin-left: 10px" v-if="form.joinScope == 2" v-model="form.joinScopeSecond" multiple placeholder="请选择指定范围（可多选）">
                            <el-option label="商户" :value="2"></el-option>
                            <el-option label="项目" :value="3"></el-option>
                            <el-option label="站点" :value="4"></el-option>
                        </el-select>
                    </div>
                    
                    <le-company-under-select 
                        v-if="form.joinScopeSecond.indexOf(2) != -1 && form.joinScope == 2"
                        label="商户"
                        placeholder="请选择商户（可多选）"
                        style="width:425px"
                        reserveKeyword 
                        :collapse='false'
                        multiple 
                        large
                        isObj
                        v-model="companyIds">
                    </le-company-under-select>
                    <span class="a-fs-12 a-c-333 a-fw-700" v-if="form.joinScopeSecond.indexOf(2) != -1 && form.joinScope == 2">共选择 {{ companyIds.length }} 商户</span>
                    <le-select-remote-search 
                        v-if="form.joinScopeSecond.indexOf(3) != -1 && form.joinScope == 2"
                        label="项目"
                        style="width:425px" 
                        reserveKeyword 
                        multiple 
                        large
                        isObj
                        :collapse='false'
                        v-model="incomeIds" 
                        :options="optionsincomeDistri" 
                        placeholder="请选择项目（可多选）" />
                    <span class="a-fs-12 a-c-333 a-fw-700" v-if="form.joinScopeSecond.indexOf(3) != -1 && form.joinScope == 2">共选择 {{ incomeIds.length }} 个项目</span>
                    <le-select-remote-search 
                        v-if="form.joinScopeSecond.indexOf(4) != -1 && form.joinScope == 2"
                        label="站点"
                        style="width:425px"
                        reserveKeyword 
                        multiple 
                        large
                        isObj
                        selectAll
                        :collapse='false'
                        v-model="stationIds" 
                        :options="optionsStation" placeholder="请选择站点（可多选）" />
                    <span class="a-fs-12 a-c-333 a-fw-700" v-if="form.joinScopeSecond.indexOf(4) != -1 && form.joinScope == 2">共选择 {{ stationIds.length }} 个站点</span>
                </el-form-item>
                <el-form-item label="用户条件" prop="limitList">
                    <el-checkbox-group v-model="form.limitList" @change='limitListChange' :disabled="id?true:false">
                        <!-- v-show="item.value != 3 || (item.value == 3 && form.activityRewardsType == 5)" -->
                        <el-checkbox :label="item.value" v-for="(item,index) in activityLimitTypeDic"  :key="index">
                            <div class="a-flex-rfsc">
                                <span>{{ item.label }}</span>
                                <el-select v-model="item.limitTypeValueMin" @change='compareTypeChange' :disabled='form.limitList.indexOf(item.value) == -1 || id ? true : false' placeholder="请选择" style="margin:0 10px">
                                    <el-option
                                        :disabled='item.value == 3 && (ite.value == 0 || ite.value == 1 || ite.value == 4)'
                                        v-for="ite in compareTypeDic"
                                        :key="ite.value"
                                        :label="ite.label"
                                        :value="ite.value">
                                        </el-option>
                                </el-select>
                                <el-input 
                                    :disabled="form.limitList.indexOf(item.value) == -1 || id ? true : false" 
                                    style="width:240px" 
                                    maxlength="4" 
                                    v-model="item.limitValueMin" 
                                    @input="item.limitValueMin=item.limitValueMin.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                                <!-- <jd-input-intnum v-model="item.limitValueMin" :disabled='form.limitList.indexOf(item.value) == -1 || id ? true : false' placeholder="请输入" maxlength='4'></jd-input-intnum> -->
                                <span class="a-ml-10" v-if="item.value != 3">天，且</span>
                                <el-select v-if="item.value != 3" v-model="item.limitTypeValueMax" @change='compareTypeChange' :disabled='form.limitList.indexOf(item.value) == -1 || id ? true : false' placeholder="请选择" style="margin:0 10px">
                                    <el-option
                                        v-for="ite in compareTypeDic"
                                        :key="ite.value"
                                        :label="ite.label"
                                        :value="ite.value">
                                        </el-option>
                                </el-select>
                                <el-input 
                                    v-if="item.value != 3"
                                    :disabled="form.limitList.indexOf(item.value) == -1 || id ? true : false" 
                                    style="width:240px" 
                                    maxlength="4" 
                                    v-model="item.limitValueMax" 
                                    @input="item.limitValueMax=item.limitValueMax.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                                <!-- <jd-input-intnum v-if="item.value != 3" v-model="item.limitValueMax" :disabled='form.limitList.indexOf(item.value) == -1 || id ? true : false' placeholder="请输入" maxlength='4'></jd-input-intnum> -->
                                <span class="a-ml-10" v-if="item.value != 3">天</span>
                                <el-button type="text" class="a-ml-10" @click="clearLimitItem(index, item)" v-if="!id">清除</el-button>
                            </div>
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="关联活动条件" prop="associationCondition">
                    <el-checkbox v-model="form.associationCondition" :disabled="id?true:false">已经参与活动</el-checkbox>
                    <div class="a-flex-cfsfs">
                        <div class="a-flex-rfsc" v-for="(item, index) in form.relationList" :key="index">
                            <le-select-remote-search isObj v-model="item.relationItem" :defaultOptions='item.relationDefaultItem' :options="optionsActivity" :readonly="!form.associationCondition || id ?true:false" placeholder="请选择活动"></le-select-remote-search>
                            <el-checkbox :true-label='1' :false-label='0' v-model="item.checkCouponUsed" :disabled="!form.associationCondition || id?true:false" class="a-ml-10">活动关联券已用完</el-checkbox>
                            <el-tooltip class="devList-btn" effect="dark" content="删除" placement="top" v-if="form.associationCondition && form.relationList.length > 1 && !id">
                                <img src="../../assets/icon/option-del-circle.png" @click="delRelationItem(index)" class="a-wh-16 a-mlr-12" />
                            </el-tooltip>
                            <el-tooltip class="devList-btn" effect="dark" content="添加" placement="top" v-if="form.associationCondition && !id">
                                <img src="../../assets/icon/option-add-circle.png" @click="addRelationItem" class="a-wh-16 a-mlr-12" />
                            </el-tooltip>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="领取周期" prop="userJoinTimes" v-if="form.activityRewardsType!=4">
                    <div class="a-flex-rfsc">
                        <span style="padding-right: 10px">每</span>
                        <el-select v-model="form.userJoinPeriod" :disabled="id || userLimitChooseMax?true:false" placeholder="请选择">
                            <el-option
                                v-for="(item,index) in userJoinTimesDic"
                                :key="index"
                                :label="item.text"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <jd-input-intnum :disabled="id||userLimitChooseMax?true: false" v-if="form.userJoinPeriod==5" class="a-ml-10" v-model="form.userJoinPeriodValue" placeholder="请输入天数" maxlength='2'></jd-input-intnum>
                        <span style="padding: 0 10px">的次数</span>
                        <el-input style="width:200px" :disabled="id||userLimitChooseMax?true:false" clearable v-model="form.userJoinTimes" placeholder="不填或输入-1则不限制"></el-input>
                    </div>
                </el-form-item>
                <el-form-item 
                    label="发放条件" 
                    prop='activityTotalTimes' 
                    v-if="(form.activityRewardsType==4 && form.activityType != 3) ||
                        (form.activityRewardsType == 2 && form.activityType == 6) ||
                        (form.activityRewardsType == 5 && form.activityType == 6)"
                >
                    <div class="a-flex-rfsc">
                        <span>总量</span>
                        <el-radio-group class="a-flex-rfsc a-ml-20" :disabled="id?true:false" v-model="form.activityTotalTimesType" @change='activityTotalTimesTypeChange'>
                            <el-radio class="a-flex-rfsc" label="1" >
                                <div class="a-flex-rfsc">不限</div>
                            </el-radio>
                            <el-radio 
                                class="a-flex-rfsc"
                                label="2" >
                                <div class="a-flex-rfsc">
                                    限量发放
                                    <div class="a-flex-rfsc">
                                        <el-input 
                                            :disabled='form.activityTotalTimesType == 1 ? true : false'
                                            style="width:160px;margin: 0 10px;" 
                                            clearable 
                                            v-model="form.activityTotalTimes" 
                                            placeholder="请输入" 
                                            @input="form.activityTotalTimes=form.activityTotalTimes.replace(/^(0+)|[^\d]+/g,'')">
                                            <template slot="append">份</template>
                                        </el-input>
                                    </div>
                                </div>
                            </el-radio>
                        </el-radio-group>
                    </div>
                    <div class="a-flex-rfsc a-mt-10">
                        <span>用户</span>
                        <el-radio-group class="a-flex-rfsc a-ml-20" :disabled="id?true:false" v-model="form.userTimesType" @change='userTimesTypeChange'>
                            <el-radio class="a-flex-rfsc" label="1" >
                                <div class="a-flex-rfsc">不限</div>
                            </el-radio>
                            <el-radio 
                                class="a-flex-rfsc"
                                label="2" >
                                <div class="a-flex-rfsc">
                                    限量发放
                                    <div class="a-flex-rfsc">
                                        <el-input 
                                            :disabled='form.userTimesType == 1 ? true : false'
                                            style="width:160px;margin: 0 10px;" 
                                            clearable 
                                            v-model="form.userTimes" 
                                            placeholder="请输入" 
                                            @input="form.userTimes=form.userTimes.replace(/^(0+)|[^\d]+/g,'')">
                                            <template slot="append">份</template>
                                        </el-input>
                                    </div>
                                </div>
                            </el-radio>
                        </el-radio-group>
                    </div>
                    
                </el-form-item>
                <el-form-item label="优惠券是否互斥" prop="couponMutual" v-if="(form.activityRewardsType == 2 && form.activityType == 6) || (form.activityRewardsType == 5 && form.activityType == 6)">
                    <el-radio-group 
                        v-model="form.couponMutual" 
                        :disabled="id?true:false">
                        <el-radio label="1" >不可同时使用</el-radio>
                        <el-radio label="0" >可同时使用</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="领取周期" prop='userJoinTimes' v-if="form.activityRewardsType==4 && form.activityType != 3">
                    <div class="a-flex-rfsc">
                        <span style="padding-right: 10px">每</span>
                        <el-select v-model="form.userJoinPeriod" :disabled="id||userLimitChooseMax?true:false" placeholder="请选择">
                            <el-option
                                v-for="(item,index) in userJoinTimesDic"
                                :key="index"
                                :label="item.text"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <jd-input-intnum :disabled="id||userLimitChooseMax?true: false" v-if="form.userJoinPeriod==5" class="a-ml-10" v-model="form.userJoinPeriodValue" placeholder="请输入天数" maxlength='2'></jd-input-intnum>
                        <span style="padding: 0 10px">的次数</span>
                        <el-input style="width:200px" :disabled="id||userLimitChooseMax?true:false" clearable v-model="form.userJoinTimes" placeholder="不填或输入-1则不限制"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="用户类型" prop="userType">
                    <div class="a-flex-cfsfs">
                        <el-select v-model="form.userType" placeholder="请选择" clearable>
                            <el-option v-for="item in userTypeDic"
                                :disabled='form.activityType == 3 && item.value != 3'
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <el-input
                            v-if="form.userType == 3"
                            type="textarea"
                            :rows="4"
                            class="inputBox a-mt-10"
                            @blur='getAppointsUser'
                            placeholder="请输入指定用户手机号，使用“,”隔开"
                            v-model="appointUser">
                        </el-input>
                        <div class="a-flex-rfsc a-flex-wrap a-mt-10" v-if="form.userType == 3" style="width: 400px">
                            <el-tag
                                style="margin: 5px 10px 5px 0"
                                closable
                                @close='delAppointUser(index)'
                                v-for="(item,index) in appointUserInfoValidListOmit"
                                :key="index"
                                effect="dark">
                                {{ item.mobile }}
                            </el-tag>
                            <span 
                                class="font-point a-c-blue text-underline" 
                                @click="showAppointUserInfoValidList"
                                v-if="form.appointUserInfoValidList.length > 10">
                                等{{ form.appointUserInfoValidList.length }}个用户，点击查看全部用户</span>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="指定用户" prop="appointUserExcelUrl" v-if="form.userType == 3">
                    <multi-file-choose :taxFiles.sync='form.appointUserExcelUrl' :accept='".xlsx,.xls"' :placeholder="'只能上传.xlsx，.xls文件'" :readonlyName='"导入用户_"' :max="1"></multi-file-choose>
                </el-form-item>
                
                <el-form-item label="活动规则条件" prop="activityTypeCondition">
                    <div class="a-flex-rfsc">
                        <el-radio-group class="a-flex-cfsfs" v-model="form.reachType" :disabled="id?true:false">
                            <el-radio 
                                class="a-flex-rfsc a-mt-10"
                                v-for="(item,index) in activityTypeConditionDic"
                                :disabled='item.value == 2 && (form.activityType == 3 || form.activityType == 6)'
                                :key="index"
                                :label="item.value" >
                                <div class="a-flex-rfsc">
                                    {{ item.text }}
                                    <div class="a-flex-rfsc" v-if="item.value == 2">
                                        <span>，满</span>
                                        <el-input 
                                            v-if="form.reachValueType==2"
                                            style="width:120px;margin: 0 10px;" 
                                            :disabled="id?true:false"
                                            clearable 
                                            v-model="form.reachValue" 
                                            placeholder="请输入次数" 
                                            @input="form.reachValue=form.reachValue.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                                        <jd-input-price 
                                            v-if="form.reachValueType==1"
                                            :disabled="id?true:false"
                                            v-model="form.reachValue" 
                                            placeholder="请输入金额"
                                            style="width:200px;margin: 0 10px;"></jd-input-price>
                                        <el-select v-model="form.reachValueType" :disabled="id?true:false" placeholder="请选择">
                                            <el-option
                                                v-for="(item,index) in reachValueTypeDic"
                                                :key="index"
                                                :label="item.text"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                                
                            </el-radio>
                        </el-radio-group>
                        
                    </div>
                    
                </el-form-item>
                <!-- <el-form-item label="关联套餐" prop="rule">
                    <el-button size="mini" icon="el-icon-plus" type="primary" @click="choosePackage">选择套餐</el-button>
                </el-form-item> -->
                <el-form-item label="失效时间" prop="startTime">
                    <div class="a-flex-rfsc" style="height:32px">
                        <el-radio-group v-model="form.activityPeriod">
                            <el-radio 
                                v-for="(item,index) in activityPeriodDic"
                                :key="index"
                                :label="item.value" >{{ item.text }}</el-radio>
                        </el-radio-group>
                        <jd-datetime-range 
                            v-if="form.activityPeriod == 0"
                            :large="true" 
                            style="margin:0;padding: 0;margin-left: 20px;" 
                            label="" 
                            valueFormat="yyyy-MM-dd HH:mm:ss" 
                            :minDate.sync="form.startTime" 
                            :maxDate.sync="form.endTime" />
                    </div>
                </el-form-item>
                <el-form-item label="活动说明" prop="description">
                    <el-input
                        type="textarea"
                        :rows="4"
                        maxlength="80"
                        class="inputBox"
                        placeholder="请输入内容"
                        v-model="form.description">
                    </el-input>
                </el-form-item>
                <el-form-item label="是否推送" prop="needPush" v-if="form.activityType == 5">
                    <el-radio-group v-model="form.needPush">
                        <el-radio label="1" >是</el-radio>
                        <el-radio label="0" >否</el-radio>
                    </el-radio-group>
                    <el-input 
                        v-if="form.needPush==1"
                        class="inputBox" 
                        clearable 
                        v-model="form.pushPath" 
                        placeholder="请输入推送页面路径" ></el-input>
                </el-form-item>
                <el-form-item label="活动弹窗海报" prop="poster" v-if="form.activityType == 5">
                    <le-upload-file-img ref="pic" v-model="form.poster" :limit='1'></le-upload-file-img>
                </el-form-item>
            </el-form>
            <div style="height: 100px;"></div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">&nbsp;&nbsp;&nbsp;返回&nbsp;&nbsp;&nbsp;</el-button>
            </div>
            <el-dialog title="指定用户" :visible.sync="dialogAppointUserVisible">
                <el-table :data="form.appointUserInfoValidList" max-height='400px'>
                    <el-table-column label="#" type="index" width="80"></el-table-column>
                    <el-table-column prop="mobile" label="用户手机号" ></el-table-column>
                    <el-table-column label="操作" width="80">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <img src="../../assets/icon/option-del-circle.png" class="a-wh-16" @click="delAppointUser(scope.$index)"/>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </el-dialog>
        </el-card>
        <le-package-choose ref="choosePackage" :defaultData="packageDatas" @selectChange="getPackageData"></le-package-choose>
        <choose-month ref="chooseMonth" :defaultData="form.monthCardInfoValidList" @selectChange="getMonthData"></choose-month>
        <le-goods-choose ref="chooseGoods" :defaultData="form.monthCardInfoValidList" @selectChange="getMonthData"></le-goods-choose>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import util from '../../../src/utils/util'
import LePackageChoose from '../components/choose/le-package-choose.vue';
import ChooseMonth from './child/chooseMonth.vue';
import multiFileChoose from '../components/formcy/multi-file-choose.vue';
import leGoodsChoose from '../components/choose/le-goods-choose.vue';
export default {
    components:{
        LePackageChoose,
        ChooseMonth,
        multiFileChoose,
        leGoodsChoose
    },
    data() {
        var checkStartTime = (rule, value, callback) => {
            if(this.form.activityPeriod==0 && !this.form.startTime){
                callback(new Error('请选择活动时间'))
            }else{
                callback()
            }
        };
        var checkRewardValue = (rule, value, callback) => {
            if(this.form.activityRewardsType==0 && !this.form.activityRewardsValue){
                callback(new Error('请输入赠送时长'))
            }else if(this.form.activityRewardsType==1 && !this.form.activityRewardsValue){
                callback(new Error('请输入立减金额'))
            }else if(this.form.activityRewardsType==2 && !this.form.activityRewardsValue){
                callback(new Error('请输入折扣'))
            }else if(this.form.activityRewardsType==3){
                if(!this.form.activityRewardsValue){
                    callback(new Error('请输入退款金额比例'))
                }else if(Number(this.form.activityRewardsValue) > 100 || Number(this.form.activityRewardsValue) < 0){
                    callback(new Error('退款金额比例区间为0-100'))
                }else{
                    callback()
                }
            }else if(this.form.activityRewardsType==4){
                if(!this.form.rewardsGoodsValidList.length){
                    callback(new Error('请添加优惠券'))
                }else{
                    let couponId = this.form.rewardsGoodsValidList.find(res=>{
                        return res.goodsInfo == '' || res.goodsInfo == null || res.goodsInfo == undefined
                    })
                    let couponNum = this.form.rewardsGoodsValidList.find(res=>{
                        return res.num == '' || res.num == null || res.num == undefined
                    })
                    let couponNum10 = this.form.rewardsGoodsValidList.find(res=>{
                        return res.num && res.num > 100
                    })
                    if(couponId) {
                        callback(new Error('请选择优惠券'))
                    }else if(couponNum) {
                        callback(new Error('请填写优惠券发放数量'))
                    }else if(couponNum10) {
                        callback(new Error('每一种优惠券发放数量最多100张'))
                    }else {
                        callback()
                    }
                }
            }else if(this.form.activityRewardsType==5 && (this.form.activityRewardsValue==='' || this.form.activityRewardsValue==='0' || this.form.activityRewardsValue===0)){
                callback(new Error('请输入一口价'))
            }else{
                callback()
            }
        };
        var checkJoinTime = (rule, value, callback) => {
            if(!this.form.userJoinPeriod){
                callback(new Error('请选择领取周期'))
            }else if(this.form.userJoinPeriod == 5 && !this.form.userJoinPeriodValue){
                callback(new Error('请输入自定义天数'))
            }else if(!this.form.userJoinTimes && this.form.userJoinPeriod != -1){
                callback(new Error('请输入周期领取次数'))
            }else if(Number(this.form.userJoinTimes) > Number(this.form.userTimes) && this.form.userTimesType == 2){
                callback(new Error('周期领取次数不可大于用户领取数'))
            }else if(this.userLimitChoose && this.form.userJoinTimes == -1){
                callback(new Error('最近充电成功下单选择了“小于”或“小于等于”时，领取次数不能输入-1'))
            }else if(this.userLimitChoose && !this.form.userJoinTimes){
                callback(new Error('请输入周期领取次数'))
            }else{
                callback()
            }
        };
        var checkJoinScope = (rule, value, callback) => {
            if(!this.form.joinScope || (!this.form.joinScopeSecond.length && this.form.joinScope == 2)){
                callback(new Error('请选择影响范围'))
            }else{
                if(this.form.joinScopeSecond.indexOf(2) != -1 && !this.companyIds.length) {
                    callback(new Error('请选择影响商户'))
                }else if(this.form.joinScopeSecond.indexOf(3) != -1 && !this.incomeIds.length) {
                    callback(new Error('请选择影响项目'))
                }else if(this.form.joinScopeSecond.indexOf(4) != -1 && !this.stationIds.length) {
                    callback(new Error('请选择影响站点'))
                }else {
                    callback()
                }
            }
        };
        var checkActivityTotalTimes = (rule, value, callback) => {
            if(this.form.activityTotalTimesType == 2 && !this.form.activityTotalTimes){
                callback(new Error('请输入发放总量'))
            }else if(this.form.userTimesType == 2 && !this.form.userTimes){
                callback(new Error('请输入用户发放总量'))
            }else {
                callback()
            }
        };
        var checkActivityType = (rule, value, callback) => {
            if(this.form.activityType == 2 && !this.form.cdk){
                callback(new Error('请输入兑换码'))
            } else if(this.form.activityType == 8 && !this.form.activityTypeLimit) {
                callback(new Error('请选择用户拥有充电优惠券条件'))
            } else if(this.form.activityType == 8 && !Number(this.form.activityTypeLimitValue)) {
                callback(new Error('请输入用户拥有充电优惠券数量'))
            } else {
                callback()
            }
        };
        var checkPushPath = (rule, value, callback) => {
            if(this.form.needPush == '1' && !this.form.pushPath){
                callback(new Error('请输入推送页面路径'))
            }else {
                callback()
            }
        };
        var checkAssociationCondition = (rule, value, callback) => {
            if(this.form.associationCondition){
                let targetInfo = this.form.relationList.find(res=>{
                    return !res.relationItem
                })
                if(targetInfo) {
                    callback(new Error('请选择关联活动'))
                }else {
                    callback()
                }
            }else {
                callback()
            }
        };
        return {
            id: '',
            util: util,
            form:{
                activityPeriod: '0',
                activityRewardsType: '0',
                activityRewardsValue: '',
                activityTitle: '',
                activityTotalTimes: -1, // 活动总次数，目前默认-1
                userTimes: -1,// 用户领取次数
                activityType: '0',
                description: '',
                userJoinPeriod: '',
                userJoinPeriodValue: '',
                userJoinTimes: '',
                startTime: '',
                endTime: '',
                reachType: '',
                reachValueType: '',
                reachValue: '',
                rewardsGoodsValidList: [{
                    goodsId: '',
                    goodsType: '1',// 目前只有优惠券
                    name: '',
                    num: '',
                    goodsInfo: '',//优惠券原信息
                }],//赠送的优惠券
                joinScope: '',
                joinScopeSecond: [],
                userType: '',
                activityTotalTimesType: '1',//优惠券发放数量类型 1--不限  2--固定数量
                userTimesType: '1',//优惠券发放用户数量类型 1--不限  2--固定数量
                appointUserInfoValidList: [],//指定用户信息
                appointUserExcelUrl: [],//指定用户Excel文件
                cdk: '',//兑换码
                monthCardInfoValidList: [],//关联套餐卡信息
                needPush: '0',
                pushPath: '',
                poster: '',
                couponMutual: '1',
                thumbnail: '',
                limitList: [],//用户活动条件
                associationCondition: false, //关联参与活动
                relationList: [{
                    checkCouponUsed: 0,
                    relationActivityId: '',
                    relationActivityName: '',
                    relationDefaultItem: '',
                    relationItem: ''
                }],////关联参与活动数据
                activityTypeLimitValue: '',//优惠券剩余条件值
                activityTypeLimit: '',//优惠券剩余条件类型
            },
            companyIds: [],//优惠券影响范围商户
            incomeIds: [],//优惠券影响范围项目
            stationIds: [],//优惠券影响范围站点
            appointUser: '',//指定用户
            isHiddenGoods: false,
            rules:{
                activityRewardsValue: [{ validator: checkRewardValue, trigger: 'blur' }],
                activityTitle: [{required: true, message:'请输入活动标题', trigger: 'blur'}],
                description: [{required: true, message:'请输入活动描述', trigger: 'blur'}],
                userJoinTimes: [{ validator: checkJoinTime, trigger: 'blur' }],
                startTime: [{ validator: checkStartTime, trigger: 'change' }],
                joinScope: [{ validator: checkJoinScope, trigger: 'change' }],
                userType: [{required: true, message:'请选择用户类型', trigger: 'change'}],
                activityTotalTimes: [{ validator: checkActivityTotalTimes, trigger: 'change' }],
                activityType: [{ validator: checkActivityType, trigger: 'change' }],
                monthCardInfoValidList: [{ type: 'array', required: true, message: '请至少选择一件关联商品', trigger: 'change' }],
                pushPath: [{ validator: checkPushPath, trigger: 'blur' }],
                associationCondition: [{ validator: checkAssociationCondition, trigger: 'change' }],
            },
            optionsCoupon: {
                url: this.$Config.apiUrl.couponPage,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
                pageSize: 1000
            },
            optionsincomeDistri: {
                url: this.$Config.apiUrl.incomeDistriList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
            },//项目数据
            optionsStation: {
                url: this.$Config.apiUrl.getStationList,
                method: "post",
                params: {
                    status: 2
                },
                showLoading: false,
                labelKey: "name",
                valueKey: "stationId",
                searchKey: "nameOrId",
            },//站点数据
            optionsActivity: {
                url: this.$Config.apiUrl.listActivity,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "activityTitle",
                valueKey: "id",
                searchKey: "activityTitle",
                pageSize: 100
            },
            defaultCouponList: [],//默认选中的优惠券
            activityRewardsTypeDic: [],//活动类型字典
            userJoinTimesDic: [],//
            activityTypeDic: [],//活动规则字典
            activityTypeConditionDic: [],//活动规则条件字典
            activityPeriodDic: [],//失效时间类型字典
            reachValueTypeDic: [],//规则条件值字典
            userTypeDic: [],//用户类型
            packageDatas: null,
            dialogAppointUserVisible: false,
            isTempCardDic: [],
            compareTypeDic: [],
            activityLimitTypeDic: []
        };
    },
    created () {
        this.form.endTime = dayjs().add(1, 'month').format('YYYY-MM-DD HH:mm:ss')
        this.form.startTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
        this.$getDic('activityRewardsType').then(res=>{ this.activityRewardsTypeDic = res; })
        this.$getDic('userJoinTimes').then(res=>{ this.userJoinTimesDic = res; })
        this.$getDic('activityType').then(res=>{ this.activityTypeDic = res; })
        this.$getDic('activityPeriod').then(res=>{ this.activityPeriodDic = res; })
        this.$getDic('reachType').then(res=>{ this.activityTypeConditionDic = res; this.form.reachType = res[0].value })
        this.$getDic('reachValueType').then(res=>{ this.reachValueTypeDic = res; this.form.reachValueType = res[0].value })
        this.$getDic('activityUserType','select').then(res=>{ this.userTypeDic = res; })
        this.$getDic('istempcard').then(res=>{ this.isTempCardDic = res; })
        this.$getDic('compareType', 'select').then(res=>{ this.compareTypeDic = res; })
        if(!this.$route.query.id) {
            this.$getDic('activityLimitType', 'select').then(res=>{ 
                this.activityLimitTypeDic = res.filter(res=>{
                    return res.value != 3
                }).map(item=>{
                    return {
                        ...item,
                        limitTypeValueMin: '',
                        limitValueMin: '',
                        limitTypeValueMax: '',
                        limitValueMax: ''
                    }
                })
            })
        }
    },
    computed: {
        appointUserInfoValidListOmit () {
            if(this.form.appointUserInfoValidList && this.form.appointUserInfoValidList.length) {
                return this.form.appointUserInfoValidList.slice(0,10)
            }else {
                return []
            }
        },
        userLimitChoose () { // 编辑活动时用户条件中“最近充电成功订单”是否选择了小于类型，若选择了则领取周期不可选择不限制
            if(this.form.limitList.indexOf('2') != -1) {
                let targetInfo = this.activityLimitTypeDic.find(res=>{
                    return res.value == '2'
                })
                if(targetInfo) {
                    if(targetInfo.limitTypeValueMin == 2 || targetInfo.limitTypeValueMin == 3 || targetInfo.limitTypeValueMax == 2 || targetInfo.limitTypeValueMax == 3) {
                        return true
                    }else {
                        return false
                    }
                }else {
                    return false
                }
            }else {
                return false
            }
        },
        userLimitChooseMax () { // 编辑活动时用户条件中“最近充电成功订单”是否选择了小于类型，若选择了则领取周期不可选择不限制
            if(this.form.limitList.indexOf('2') != -1) {
                let targetInfo = this.activityLimitTypeDic.find(res=>{
                    return res.value == '2'
                })
                if(targetInfo) {
                    if(targetInfo.limitTypeValueMin === '0' || targetInfo.limitTypeValueMin == 1 || targetInfo.limitTypeValueMax === '0' || targetInfo.limitTypeValueMax == 1) {
                        return true
                    }else {
                        return false
                    }
                }else {
                    return false
                }
            }else {
                return false
            }
        },
    },
    watch:{
        "form.rewardsGoodsValidList": {
            deep: true,
            handler (val) {
                if(this.id) {
                    return
                }
                this.companyIds = []
                this.incomeIds = []
                this.stationIds = []
                this.$set(this.form,'joinScopeSecond',[])
                val.map(item=>{
                    if(item.goodsInfo.scopeValidList && item.goodsInfo.scopeValidList.length) {
                        item.goodsInfo.scopeValidList.map(ite=>{
                            if(ite.scopeType == 1) {
                                let companyIndex = this.companyIds.findIndex(res=>{
                                    return res.value == ite.scopeId
                                })
                                if(companyIndex == -1) {
                                    this.companyIds.push({
                                        label: ite.name,
                                        value: ite.scopeId
                                    })
                                }
                            }
                            if(ite.scopeType == 2) {
                                let incomeIndex = this.incomeIds.findIndex(res=>{
                                    return res.value == ite.scopeId
                                })
                                if(incomeIndex == -1) {
                                    this.incomeIds.push({
                                        label: ite.name,
                                        value: ite.scopeId
                                    })
                                }
                            }
                            if(ite.scopeType == 3) {
                                let stationIndex = this.stationIds.findIndex(res=>{
                                    return res.value == ite.scopeId
                                })
                                if(stationIndex == -1) {
                                    this.stationIds.push({
                                        label: ite.name,
                                        value: ite.scopeId
                                    })
                                }
                                
                            }
                        })
                    }
                    
                })

                if(this.companyIds.length) {
                    this.form.joinScopeSecond.push(2)
                }
                if(this.incomeIds.length) {
                    this.form.joinScopeSecond.push(3)
                }
                if(this.stationIds.length) {
                    this.form.joinScopeSecond.push(4)
                }
                
                if(!this.companyIds.length && !this.incomeIds.length && !this.stationIds.length) {
                    this.form.joinScope = 1
                }
            }
        },
        activityLimitTypeDic: {
            deep: true,
            handler (val) {
                if(this.form.limitList.indexOf('2') != -1) {
                    let targetInfo = this.activityLimitTypeDic.find(res=>{
                        return res.value == '2'
                    })
                    if(targetInfo) {
                        if(targetInfo.limitTypeValueMin === '0' || targetInfo.limitTypeValueMin == 1 || targetInfo.limitTypeValueMax === '0' || targetInfo.limitTypeValueMax == 1) {
                            this.form.userJoinPeriod = '5'
                            this.form.userJoinTimes = 1
                            let limitValueMin = 0, limitValueMax = 0
                            if(targetInfo.limitTypeValueMin === '0' || targetInfo.limitTypeValueMin == 1) {
                                limitValueMin = targetInfo.limitValueMin
                            }
                            if(targetInfo.limitTypeValueMax === '0' || targetInfo.limitTypeValueMax == 1) {
                                limitValueMax = targetInfo.limitValueMax
                            }
                            this.form.userJoinPeriodValue = limitValueMin >= limitValueMax ? limitValueMin : limitValueMax
                        }
                    }
                }
            }
        }
    },
    mounted () {
        this.id = this.$route.query.id?this.$route.query.id:''
        this.$nextTick(()=>{
            this.setActivityTypeDisabled()
        })
        if(this.id) {
            this.getActivityInfo()
        }
    },
    methods: {
        choosePackage () {
            this.$refs['chooseMonth'].dialogVisible = true
            return
            if(this.form.activityRewardsType == 5) {
                this.$refs['chooseGoods'].dialogVisible = true
            }else {
                this.$refs['chooseMonth'].dialogVisible = true
            }
        },
        getPackageData (datas) {
            this.packageDatas = datas
        },
        rewardsTypeChange () {
            this.form.activityRewardsValue = ''
            this.form.activityType = '0'
            this.form.activityTotalTimesType = '1'
            this.form.activityTotalTimes = ''
            this.form.userTimesType = '1'
            this.form.userTimes = ''
            this.form.reachType = '1'
            // if(this.form.activityRewardsType == 5) {
            //     this.form.activityType = '6'
            // }
            this.form.monthCardInfoValidList = []
            this.setActivityTypeDisabled()

            if(this.form.activityRewardsType != 5) {
                this.$getDic('activityLimitType', 'select').then(res=>{ 
                    this.activityLimitTypeDic = res.filter(res=>{
                        return res.value != 3
                    }).map(item=>{
                        return {
                            ...item,
                            limitTypeValueMin: '',
                            limitValueMin: '',
                            limitTypeValueMax: '',
                            limitValueMax: ''
                        }
                    })
                })
            }else {
                this.$getDic('activityLimitType', 'select').then(res=>{ 
                    this.activityLimitTypeDic = res.map(item=>{
                        return {
                            ...item,
                            limitTypeValueMin: '',
                            limitValueMin: '',
                            limitTypeValueMax: '',
                            limitValueMax: ''
                        }
                    })
                })
            }
        },
        activityTypeChange () {
            if((this.form.activityRewardsType == 4 && this.form.activityType == 4) || this.form.activityRewardsType == 5) {
                this.$getDic('activityLimitType', 'select').then(res=>{ 
                    this.activityLimitTypeDic = res.map(item=>{
                        return {
                            ...item,
                            limitTypeValueMin: '',
                            limitValueMin: '',
                            limitTypeValueMax: '',
                            limitValueMax: ''
                        }
                    })
                })
            }else {
                this.$getDic('activityLimitType', 'select').then(res=>{ 
                    this.activityLimitTypeDic = res.filter(res=>{
                        return res.value != 3
                    }).map(item=>{
                        return {
                            ...item,
                            limitTypeValueMin: '',
                            limitValueMin: '',
                            limitTypeValueMax: '',
                            limitValueMax: ''
                        }
                    })
                })
            }
        },
        setActivityTypeDisabled () {
            if(this.form.activityRewardsType == 0) {
                this.activityTypeDic = this.activityTypeDic.map(item=>{
                    let isDisabled = false
                    if(item.value != 0) {
                        isDisabled = true
                    }
                    return {
                        ...item,
                        isDisabled: isDisabled
                    }
                })
            }else if(this.form.activityRewardsType == 1) {
                this.activityTypeDic = this.activityTypeDic.map(item=>{
                    let isDisabled = false
                    if(item.value != 0) {
                        isDisabled = true
                    }
                    return {
                        ...item,
                        isDisabled: isDisabled
                    }
                })
            }else if(this.form.activityRewardsType == 2) {
                this.activityTypeDic = this.activityTypeDic.map(item=>{
                    let isDisabled = false
                    if(item.value != 0 && item.value != 6) {
                        isDisabled = true
                    }
                    return {
                        ...item,
                        isDisabled: isDisabled
                    }
                })
            }else if(this.form.activityRewardsType == 3) {
                this.activityTypeDic = this.activityTypeDic.map(item=>{
                    let isDisabled = false
                    if(item.value != 0) {
                        isDisabled = true
                    }
                    return {
                        ...item,
                        isDisabled: isDisabled
                    }
                })
            }else if(this.form.activityRewardsType == 4) {
                this.activityTypeDic = this.activityTypeDic.map(item=>{
                    let isDisabled = false
                    if(item.value == 6) {
                        isDisabled = true
                    }
                    return {
                        ...item,
                        isDisabled: isDisabled
                    }
                })
            }else if(this.form.activityRewardsType == 5) {
                this.activityTypeDic = this.activityTypeDic.map(item=>{
                    let isDisabled = false
                    if(item.value != 6 && item.value != 0) {
                        isDisabled = true
                    }
                    return {
                        ...item,
                        isDisabled: isDisabled
                    }
                })
            }
        },
        // 清除用户条件
        clearLimitItem (index, item) {
            this.$set(this.activityLimitTypeDic, index, {
                ...item,
                limitTypeValueMin: '',
                limitValueMin: '',
                limitTypeValueMax: '',
                limitValueMax: ''
            })
        },
        // 删除关联活动
        delRelationItem (index) {
            this.form.relationList.splice(index,1)
            this.$refs['form'].validateField(['associationCondition'])
        },
        // 添加关联活动
        addRelationItem () {
            if(this.form.relationList.length >= 10) {
                this.$message.warning('最多添加10个关联活动')
                return
            }
            this.form.relationList.push({
                checkCouponUsed: 0,
                relationActivityId: '',
                relationActivityName: '',
                relationDefaultItem: '',
                relationItem: ''
            })
        },
        // 添加优惠券
        addCoupon () {
            this.form.rewardsGoodsValidList.push({
                goodsId: '',
                goodsType: '1',// 目前只有优惠券
                name: '',
                num: '',
                goodsInfo: '',//优惠券原信息
            })
        },
        // 删除优惠券
        delCoupon (index) {
            this.form.rewardsGoodsValidList.splice(index,1)
        },
        activityTotalTimesTypeChange () {
            this.form.activityTotalTimes = ''
        },
        userTimesTypeChange () {
            this.form.userTimes = ''
        },
        limitListChange () {
            this.form.userJoinPeriod = ''
            this.form.userJoinPeriodValue = ''
            this.form.userJoinTimes = ''
        },
        // 用户条件修改时 重置领取周期
        compareTypeChange () {
            this.form.userJoinPeriod = ''
            this.form.userJoinPeriodValue = ''
            this.form.userJoinTimes = ''
        },
        // 获取指定用户信息
        getAppointsUser () {
            let text = this.appointUser.replace(/，/g, ",")
            let arr = text.split(',')
            arr = arr.filter(item=>{
                const regExp = /^1[3-9]\d{9}$/;
                return regExp.test(item);
            })
            this.$Axios._post({
                url: this.$Config.apiUrl.getUserInfoByMobile,
                method: "post",
                params: {
                    mobiles: arr
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.form.appointUserInfoValidList = res.result.data.map(item=>{
                        return {
                            mobile: item.mobile,
                            userId: item.userId
                        }
                    })
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        // 删除指定用户
        delAppointUser (index) {
            this.form.appointUserInfoValidList.splice(index,1)
        },
        // 查看所有指定用户
        showAppointUserInfoValidList () {
            this.dialogAppointUserVisible = true
        },
        // 添加关联套餐
        getMonthData (datas) {
            this.form.monthCardInfoValidList = datas.concat(this.form.monthCardInfoValidList)
            this.form.monthCardInfoValidList = this.uniqueArr(this.form.monthCardInfoValidList)
        },
        // 删除关联套餐
        delMonthCardInfoValidList (index) {
            this.form.monthCardInfoValidList.splice(index,1)
        },
        getActivityInfo () {
            this.$Axios._post({
                url: this.$Config.apiUrl.activityInfo,
                method: "post",
                params: {
                    id: this.id
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.form = {
                        ...res.result.data,
                        associationCondition: res.result.data.relationList.length ? true : false,
                        appointUserExcelUrl: res.result.data.appointUserExcelUrl ? [{
                            name: res.result.data.appointUserExcelUrl,
                            url: res.result.data.appointUserExcelUrl
                        }] : []
                    }
                    this.form.activityPeriod = JSON.stringify(this.form.activityPeriod)
                    this.form.activityRewardsType = JSON.stringify(this.form.activityRewardsType)
                    this.form.activityType = JSON.stringify(this.form.activityType)
                    this.form.userJoinPeriod = JSON.stringify(this.form.userJoinPeriod)
                    this.form.reachType = JSON.stringify(this.form.reachType)
                    this.form.reachValueType = JSON.stringify(this.form.reachValueType)
                    this.form.userType = JSON.stringify(this.form.userType)
                    this.form.needPush = JSON.stringify(this.form.needPush)
                    this.form.couponMutual = JSON.stringify(this.form.couponMutual)
                    this.form.activityTypeLimit = JSON.stringify(this.form.activityTypeLimit)

                    if(this.form.activityRewardsType == 1 || this.form.activityRewardsType == 5){
                        this.form.activityRewardsValue = this.form.activityRewardsValue?Number((this.form.activityRewardsValue/100).toFixed(2)):0
                    }else if(this.form.activityRewardsType == 3){
                        this.form.activityRewardsValue = this.form.activityRewardsValue?Number((this.form.activityRewardsValue*100).toFixed(0)):0
                    }
                    if(this.form.reachValueType == 1){
                        this.form.reachValue = this.form.reachValue?Number((this.form.reachValue/100).toFixed(2)):0
                    }

                    if(this.form.activityRewardsType != 5 && this.form.activityType != 4) {
                        this.$getDic('activityLimitType', 'select').then(re=>{ 
                            this.activityLimitTypeDic = re.filter(r=>{
                                return r.value != 3
                            }).map(item=>{
                                return {
                                    ...item,
                                    limitTypeValueMin: '',
                                    limitValueMin: '',
                                    limitTypeValueMax: '',
                                    limitValueMax: ''
                                }
                            })
                        })
                    }else {
                        this.$getDic('activityLimitType', 'select').then(re=>{ 
                            this.activityLimitTypeDic = re.map(item=>{
                                return {
                                    ...item,
                                    limitTypeValueMin: '',
                                    limitValueMin: '',
                                    limitTypeValueMax: '',
                                    limitValueMax: ''
                                }
                            })
                        })
                    }
                    // 用户条件
                    setTimeout(()=>{
                        this.activityLimitTypeDic.map((item,index)=>{
                            let targetArr = res.result.data.limitList.filter(ite=>{
                                return item.value == ite.type
                            })
                            if(targetArr.length) {
                                this.$set(this.activityLimitTypeDic, index, {
                                    ...item,
                                    limitTypeValueMin: targetArr[0].valueType || targetArr[0].valueType === 0?JSON.stringify(targetArr[0].valueType):'',
                                    limitValueMin: targetArr[0].value || '',
                                    limitTypeValueMax: targetArr[1] && (targetArr[1].valueType || targetArr[1].valueType === 0)?JSON.stringify(targetArr[1].valueType):'',
                                    limitValueMax: targetArr[1] && targetArr[1].value ? targetArr[1].value : '',
                                })
                            }
                        })
                    },200)
                    let limitList = res.result.data.limitList.map(item=>{
                        return JSON.stringify(item.type)
                    })
                    this.form.limitList = [...new Set(limitList)]

                    // 关联活动
                    this.form.relationList = res.result.data.relationList.map(item=>{
                        return {
                            ...item,
                            relationDefaultItem: {
                                label: item.relationActivityName,
                                value: item.relationActivityId
                            },
                            relationItem: {
                                label: item.relationActivityName,
                                value: item.relationActivityId
                            }
                        }
                    })



                    // 初始化影响范围
                    this.companyIds = []
                    this.incomeIds = []
                    this.stationIds = []
                    // this.form.joinScopeSecond = new Array()
                    this.$set(this.form,'joinScopeSecond',[])
                    this.form.scopeValidList.map(item=>{
                        if(item.scopeType == 1) {
                            this.companyIds.push({
                                label: item.name,
                                value: item.scopeId
                            })
                        }
                        if(item.scopeType == 2) {
                            this.incomeIds.push({
                                label: item.name,
                                value: item.scopeId
                            })
                        }
                        if(item.scopeType == 3) {
                            this.stationIds.push({
                                label: item.name,
                                value: item.scopeId
                            })
                        }
                    })
                    if(this.companyIds.length) {
                        this.form.joinScopeSecond.push(2)
                    }
                    if(this.incomeIds.length) {
                        this.form.joinScopeSecond.push(3)
                    }
                    if(this.stationIds.length) {
                        this.form.joinScopeSecond.push(4)
                    }

                    // 初始化优惠券
                    if(this.form.activityRewardsType == 4) {
                        this.form.rewardsGoodsValidList = this.form.rewardsGoodsValidList.map(item=>{
                            return {
                                goodsId: item.goodsId,
                                goodsType: item.goodsType,// 目前只有优惠券
                                name: item.name,
                                num: item.num,
                                goodsInfo: {
                                    label: item.name,
                                    value: item.goodsId
                                },//优惠券原信息
                            }
                        })
                    }

                    //初始化发放条件
                    if(this.form.activityTotalTimes == -1) {
                        this.form.activityTotalTimesType = '1'
                    }else {
                        this.form.activityTotalTimesType = '2'
                    }
                    if(this.form.userTimes == -1) {
                        this.form.userTimesType = '1'
                    }else {
                        this.form.userTimesType = '2'
                    }
                    this.setActivityTypeDisabled()
                } else {
                    this.$message.error(res.result.message)
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        submit () {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$confirm('是否确认提交？','温馨提示')
                    .then(_=>{
                        let limitList = []
                        // 用户条件
                       
                        this.activityLimitTypeDic.map(item=>{
                            if(this.form.limitList.indexOf(item.value) != -1) {
                                if(item.limitTypeValueMin && item.limitValueMin) {
                                    limitList.push({
                                        type: item.value,
                                        value: item.limitValueMin,
                                        valueType: item.limitTypeValueMin
                                    })
                                }
                                if(item.limitTypeValueMax && item.limitValueMax) {
                                    limitList.push({
                                        type: item.value,
                                        value: item.limitValueMax,
                                        valueType: item.limitTypeValueMax
                                    })
                                }
                            }
                        })
                        // 关联活动
                        let relationList = []
                        if(this.form.associationCondition) {
                            this.form.relationList.map(item=>{
                                relationList.push({
                                    ...item,
                                    relationActivityId: item.relationItem.value,
                                    relationActivityName: item.relationItem.label
                                })
                            })
                        }

                        let reqData = {
                            id: this.id,
                            ...this.form,
                            userJoinTimes: this.form.userJoinTimes?this.form.userJoinTimes:-1,
                            activityTotalTimes: this.form.activityTotalTimesType == '1' ? -1 : this.form.activityTotalTimes,
                            userTimes: this.form.userTimesType == '1' ? -1 : this.form.userTimes,
                            appointUserExcelUrl: this.form.appointUserExcelUrl.length ? this.form.appointUserExcelUrl[0].url : '',
                            limitList: limitList,
                            relationList: relationList
                        }
                        if(reqData.activityRewardsType == 1 || reqData.activityRewardsType == 5){
                            reqData.activityRewardsValue = Number((reqData.activityRewardsValue*100).toFixed(0))
                        }else if(reqData.activityRewardsType == 3){
                            reqData.activityRewardsValue = Number((reqData.activityRewardsValue/100).toFixed(2))
                        }
                        if(this.form.reachValueType == 1){
                            reqData.reachValue = Number((reqData.reachValue*100).toFixed(0))
                        }

                        // 初始化影响范围
                        if(this.form.joinScope == 2) {
                            let companyIds = this.companyIds.map(item=>{
                                return {
                                    ...item,
                                    name: item.label,
                                    scopeId: item.value,
                                    scopeType: 1
                                }
                            })

                            let incomeIds = this.incomeIds.map(item=>{
                                return {
                                    ...item,
                                    name: item.label,
                                    scopeId: item.value,
                                    scopeType: 2
                                }
                            })

                            let stationIds = this.stationIds.map(item=>{
                                return {
                                    ...item,
                                    name: item.label,
                                    scopeId: item.value,
                                    scopeType: 3
                                }
                            })

                            let scopeValidList = []
                            if(this.form.joinScopeSecond.indexOf(2) != -1) {
                                scopeValidList = companyIds
                            }

                            if(this.form.joinScopeSecond.indexOf(3) != -1) {
                                scopeValidList = scopeValidList.concat(incomeIds)
                            }

                            if(this.form.joinScopeSecond.indexOf(4) != -1) {
                                scopeValidList = scopeValidList.concat(stationIds)
                            }

                            // let scopeValidList = companyIds.concat(incomeIds.concat(stationIds))
                            reqData.scopeValidList = scopeValidList
                        }else {
                            reqData.scopeValidList = []
                        }
                        

                        // 初始化优惠券信息
                        if(this.form.activityRewardsType == 4) {
                            reqData.rewardsGoodsValidList = reqData.rewardsGoodsValidList.map(item=>{
                                return {
                                    ...item,
                                    name: item.goodsInfo.label || '',
                                    goodsId: item.goodsInfo.value || ''
                                }
                            })
                        }
                        if(this.id){
                            this.updateActivity(reqData)
                        }else{
                            this.createActivity(reqData)
                        }
                        
                    })
                    .catch(_=>{})
                    
                }
            })
        },
        createActivity (reqData) {
            this.$Axios._post({
                url: this.$Config.apiUrl.addNewActivity,
                method: "post",
                params: reqData
            })
            .then(res=>{
                if (res.result.code == 0) {
                    if(this.form.activityType == 5) {
                        this.$message.success('操作成功')
                        this.$router.back()
                        this.$store.dispatch("delView", this.$route);
                    }else {
                        this.$confirm('活动创建成功，您可以为该活动创建推送通知，是否创建？','操作成功', {
                            confirmButtonText: '去创建',
                            cancelButtonText: '取消'
                        })
                        .then(_=>{
                            this.$router.push({
                                path: '/activity/push-edit',
                                query:{
                                    activityId: res.result.data.id,
                                    activityName: res.result.data.name
                                }
                            })
                        })
                        .catch(_=>{
                            this.$router.back()
                            this.$store.dispatch("delView", this.$route);
                        })
                    }
                    
                } else {
                    this.$message.error(res.result.message)
                }
            })
            .catch(err=>{

            })
        },  
        updateActivity (reqData) {
            this.$Axios._post({
                url: this.$Config.apiUrl.updateActivity,
                method: "post",
                params: reqData
            })
            .then(res=>{
                if (res.result.code == 0) {
                    this.$message.success('操作成功')
                    this.$router.back()
                    this.$store.dispatch("delView", this.$route);
                } else {
                    this.$message.error(res.result.message)
                }
            })
            .catch(err=>{

            })
        },
        cancelSubmit () {
            this.$confirm('是否确认返回？','温馨提示')
            .then(_=>{
                this.$router.back()
                this.$store.dispatch("delView", this.$route);
            })
            .catch(_=>{})
        },
        //数组去重
        uniqueArr (arr) {
            try{
                for(var i=0; i<arr.length; i++){
                    for(var j=i+1; j<arr.length; j++){
                        if(arr[i].goodsId==arr[j].goodsId){         //第一个等同于第二个，splice方法删除第二个
                            arr.splice(j,1);
                            j--;
                        }
                    }
                }
                return arr;
            }catch(err){
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.monthCard-edit{
    font-size: 14px;
    height: 100%;
    overflow-y: auto;
    /deep/ .s-search-label {
		width: 145px !important;
		justify-content: flex-start;
	}
    /deep/ .s-nomarl-input .el-input__inner {
		width: 200px;
	}
    /deep/ .date-range{
        margin: 0;
    }
    /deep/ .projectBox{
        &>div{
            margin: 0;
        }
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
}
.inputBox{
    width: 400px;
}
.addBankBtn{
    padding: 0 20px;
    font-size: 14px;
    color: #007AFF;
    line-height: 34px;
    cursor: pointer;
}
.a-mb-10{
    margin-bottom: 10px;
}
/deep/ .coupon-item{
    &>div{
        margin-left: 0 !important;
    }
}
.stationTag{
    padding: 0px 6px;
    background: #f4f4f5;
    color: #909399;
    border: 1px solid #e9e9eb;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    word-break: keep-all;
}
</style>